html, body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

#root {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.chrome-picker {
  box-shadow: none !important;
}

.assignment-timer-bar + .assignment-form {
  margin-top: 41px;
}