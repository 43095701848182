.embed-button-wrapper {
  z-index: 999;
  position: fixed;
  bottom: 78px;
  right: 8px;

  .embed-button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgb(0, 0, 0);
    cursor: pointer;
    padding: 0;

    .embed-button-icon {
      .embed-button-svg {
        display: block;
        width: 64px;
      }
    }
  }
}

.embed-button-dialog {
  .embed-button-dialog-paper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(17, 17, 17, 0.8);
    padding: 12px;

    .html-textarea {
      border: none;
      cursor: text;
      font-family: "Roboto Mono", monospace;
      font-size: 14px;
      font-weight: 400;
      height: 175px;
      line-height: 24px;
      position: relative;
      resize: none;
      text-transform: none;
      white-space: pre-wrap;
      width: 100%;
    }

    .copy-button-row {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;

      .copy-button-row-inner {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}